import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { StickyContainer } from 'react-sticky';
import Spinner from 'react-svg-spinner';

import { PARTICULARITIES_ICON } from '@/components/icons';

import { useData } from '@/helpers/data';
import { parseMetafield } from '@/helpers/shopify/metafields';
import { render } from '@/helpers/storyblok/rich-text';
import { formatCurrency } from '@/helpers/text';

import { Button } from '../button';
import { useCartActions } from '../cart/actions';
import EditQuantity from '../edit-quantity';
import { ChevronDown } from '../icons/index.js';
import { MaxWidth } from '../max-width';
import { ProductHeroBreadCrumb } from './breadcrumb';
import { ProductHeroSlider } from './slider';

delete Spinner.defaultProps;

export const ProductHero = ({ product, breadcrumb }) => {
  const { layouts } = useData();

  const { addItem } = useCartActions();

  const [currentChoiceIndex, setCurrentChoiceIndex] = useState(0);
  const [variant, setVariant] = useState(product.variants[currentChoiceIndex]);
  const [productQuantity, setProductQuantity] = useState(1);
  const [loadingAddToCart, setLoadingAddToCart] = useState(false);
  const [fullHeight, setFullHeight] = useState(false);

  const layout = layouts.product;

  const particularities = parseMetafield(product.particularities);

  const handleAddToCartClick = async () => {
    if (!variant?.id) {
      return alert('Please select an option');
    }

    setLoadingAddToCart(true);

    await addItem({
      shopifyProduct: product,
      variant,
      quantity: productQuantity,
    });

    setLoadingAddToCart(false);
  };

  useEffect(() => {
    setVariant(product.variants[currentChoiceIndex]);
  }, [currentChoiceIndex]);

  return (
    <div className="product-hero">
      <MaxWidth width="small">
        <ProductHeroBreadCrumb breadcrumb={breadcrumb} title={product?.title} />
        <div className="product-hero__content">
          {product?.images.length > 0 ? (
            <StickyContainer>
              <ProductHeroSlider
                images={product?.images?.map((image) => ({
                  filename: image.url,
                  alt: image.altText,
                }))}
                selectedVariant={variant}
              />
            </StickyContainer>
          ) : null}

          <div className="product-hero__content__informations">
            <h1 className="product-hero__content__informations__title">
              {product.title}
            </h1>

            {Array.isArray(particularities) && particularities.length > 0 ? (
              <div className="product-hero__content__informations__particularities">
                {particularities.map((item, index) => {
                  return (
                    <p
                      key={index}
                      className="product-hero__content__informations__particularity"
                    >
                      {PARTICULARITIES_ICON[item.toLowerCase()]}
                      {item}
                    </p>
                  );
                })}
              </div>
            ) : null}

            <div
              className="product-hero__content__informations__description product-hero__content__informations__description--desktop"
              dangerouslySetInnerHTML={{
                __html: product.descriptionHtml,
              }}
            />
            <hr className="product-hero__content__informations__separator" />
            <div className="product-hero__content__informations__choice">
              {/* TODO: */}
              {product.options && product.options[0] ? (
                <h3 className="product-hero__content__informations__choice__title">
                  {product.options[0].name}
                </h3>
              ) : null}

              <div className="product-hero__content__informations__choice__mobile-grid">
                <div className="product-hero__content__informations__choice__options">
                  {product.variants.map((item, index) => {
                    return (
                      <button
                        key={index}
                        className={classNames(
                          'product-hero__content__informations__choice__option',
                          {
                            'product-hero__content__informations__choice__option--active':
                              currentChoiceIndex === index,
                          },
                        )}
                        onClick={() => {
                          setCurrentChoiceIndex(index);
                        }}
                      >
                        {item.title}
                      </button>
                    );
                  })}
                </div>

                <p className="product-hero__content__informations__choice__price">
                  {formatCurrency(
                    product.variants[currentChoiceIndex].price.amount,
                    {
                      currency:
                        product.variants[currentChoiceIndex].price.currencyCode,
                    },
                  )}
                </p>
              </div>

              <div className="product-hero__content__informations__choice__purchase">
                <EditQuantity
                  productQuantity={productQuantity}
                  setProductQuantity={setProductQuantity}
                  enableInput={true}
                />

                <Button
                  width="full"
                  theme="black"
                  onClick={handleAddToCartClick}
                >
                  {loadingAddToCart ? (
                    <Spinner
                      color="white"
                      speed="fast"
                      size="22"
                      gap={4}
                      thickness={4}
                    />
                  ) : (
                    layouts.product?.content?.addToCartLabel
                  )}
                </Button>
              </div>
            </div>
            <div
              className={classNames(
                'product-hero__content__informations__description__wrap',
                {
                  'product-hero__content__informations__description__wrap--full-height':
                    fullHeight,
                },
              )}
            >
              <div
                className="product-hero__content__informations__description product-hero__content__informations__description--mobile"
                dangerouslySetInnerHTML={{
                  __html: product.descriptionHtml,
                }}
              />
              <div className="product-hero__content__informations__description">
                {render(layout?.content?.shippingDetails)}
              </div>
            </div>
            <a
              className="product-hero__content__informations__description__read-more"
              onClick={() => {
                setFullHeight(true);
              }}
            >
              {layout?.content?.readMoreTitle}
              <ChevronDown />
            </a>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
