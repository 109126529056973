import imageFragment from './image';
import seoFragment from './seo';

const metafieldFragment = /* GraphQL */ `
  fragment metafield on Metafield {
    key
    value
    references(first: 10) {
      edges {
        node {
          ... on Metaobject {
            id
            fields {
              key
              type
              value
              reference {
                ... on MediaImage {
                  id
                  image {
                    url(transform: { maxWidth: 1200 })
                    width
                    height
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const productFragment = /* GraphQL */ `
  ${metafieldFragment}
  fragment product on Product {
    id
    handle
    availableForSale
    title
    description
    descriptionHtml
    options {
      id
      name
      values
    }
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    particularities: metafield(namespace: "custom", key: "particularities") {
      ...metafield
    }
    slider: metafield(namespace: "custom", key: "slider") {
      ...metafield
    }
    tasting_advices: metafield(namespace: "custom", key: "tasting_advices") {
      ...metafield
    }
    reviews: metafield(namespace: "custom", key: "reviews") {
      ...metafield
    }
    variants(first: 250) {
      edges {
        node {
          id
          title
          availableForSale
          selectedOptions {
            name
            value
          }
          price {
            amount
            currencyCode
          }
          image {
            ...image
          }
        }
      }
    }
    featuredImage {
      ...image
    }
    images(first: 20) {
      edges {
        node {
          ...image
        }
      }
    }
    seo {
      ...seo
    }
    tags
    updatedAt
  }
  ${imageFragment}
  ${seoFragment}
`;

export default productFragment;
